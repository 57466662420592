<template>
	<div class="ins-about">
		<div class="layout">
			<ul class="ins-aboutMenu">
				<li>
					<router-link to="/about">企业概况</router-link>
				</li>
				<li>
					<router-link to="/about/culture">企业文化</router-link>
				</li>
				<li>
					<router-link to="/about/history">发展历程</router-link>
				</li>
				<li>
					<router-link to="/about/certificaties">企业荣誉</router-link>
				</li>
			</ul>
			<!-- <a href="javascript:void(0);" @click="scrollBar" class="return-top">回到顶部</a> -->
		</div>
	</div>
</template>
<script>
export default ({
	methods:{
		 scrollBar(){
			document.documentElement.scrollTop = 0
		}
	}
});
</script>
