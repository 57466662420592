<template>
	<div class="about_US">
		<div class="page_banner">			<img src="@/assets/images/about/about.jpg" />
			<div class="words">
				<p>
					<span>发展历程</span>
					争创百年品牌，服务为您
				</p>
			</div>
		</div>
		<menu-layout></menu-layout>
		<div class="layout profile clearfix">
			<h1 class="enter">
				发展历程
			</h1>
			<div class="cont">

				<p>
					建设中...
				</p>
			</div>
		</div>

	</div>
</template>
<script>
import menuLayout from "@/components/aboutMenu"
export default ({
	components: {
		menuLayout
	},
	methods: {

	}
});
</script>
